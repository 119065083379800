import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider, Chip } from '@material-ui/core'

import Tabs, { tabMap } from './Tabs'

import Statistics from './Statistics'
import UnderConstruction from '../../Components/UnderConstruction'

const useStyles = makeStyles(theme => ({
    sidebarTitle: props => {
        if (!props.tooltip) {
            return {
                fontWeight: 300,
            }
        }

        return {
            fontWeight: 400,
        }
    },

    sidebarContentSelector: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
    },

    chip: {
        // padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}))

export default observer(({ city, lite, tooltip }) => {
    const classes = useStyles({ tooltip })

    const [selectedTab, setSelectedTab] = useState(tabMap.statistics)

    if (!lite && tooltip) lite = tooltip

    return (
        <>
            <Typography className={classes.sidebarTitle} variant="h4" gutterBottom>
                {city.name}
            </Typography>

            {!lite ? (
                <>
                    <Divider />
                    <div className={classes.sidebarContentSelector}>
                        {Tabs.map((tab, index) => (
                            <Chip
                                key={index}
                                onClick={() => {
                                    setSelectedTab(tab.key)
                                }}
                                clickable
                                color="primary"
                                label={tab.name}
                                className={classes.chip}
                                icon={tab.icon()}
                                variant={selectedTab === tab.key ? 'default' : 'outlined'}
                            />
                        ))}
                    </div>

                    {selectedTab === 'statistics' ? <Statistics city={city} /> : null}
                    {selectedTab === 'news' ? <UnderConstruction /> : null}
                    {selectedTab === 'projections' ? <UnderConstruction /> : null}
                </>
            ) : null}

            {lite ? (
                <>
                    <Statistics lite={lite} city={city} tooltip={tooltip} />
                </>
            ) : null}
        </>
    )
})
