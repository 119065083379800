import React from 'react'
import ReactDOM from 'react-dom'

import 'typeface-roboto'

import './index.css'

import App from './App'

ReactDOM.render(<App />, document.querySelector('#root'))
