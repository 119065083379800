import * as mobx from 'mobx'

import Corona from 'corona-sdk'
import { createContext } from 'react'

import getBrowserLocale from './utils/browser/getBrowserLocale'

import Brazil from './Modules/Countries/Brazil/Brazil'

const { decorate, observable, action } = mobx

class AppState {
    // UI Hacks
    initalHeight = 0

    initialWidth = 0

    isWeb = undefined

    showLiteCard = true

    // Base data
    status = 'init'

    currentUser = null

    contextState = null

    blurSearchbar = false

    getMapHeight(height) {
        if (height === this.initalHeight) return height

        return height + this.initalHeight
    }

    // Search Bar
    searchBar = ''

    setSearchBar = el => {
        this.searchBar = el
    }

    // Current City
    selectedCity = null

    setSelectedCity = (city, flyTo = false, openSideBar = this.isWeb) => {
        this.selectedCity = city
        this.searchBar = city.name

        if (flyTo && this.flyToCity) {
            this.flyToCity(city)
        }

        this.blurSearchbar = true

        setTimeout(() => {
            this.showLiteCard = true
            this.blurSearchbar = false
        }, 10)

        this.drawerContent = 'city'

        if (openSideBar) {
            this.openSideBar()
        }
    }

    clearSelectedCity = () => {
        this.searchBar = ''
        this.selectedCity = this.brazil.countryDetails
    }

    // Side Bar
    sideBarIsOpen = this.isWeb

    drawerContent = 'city'

    setDrawerContent(content) {
        this.drawerContent = content

        this.openSideBar()
    }

    openSideBar = () => {
        this.sideBarIsOpen = true
    }

    closeSideBar = () => {
        this.sideBarIsOpen = false
    }

    // Base setup
    constructor() {
        this.locale = getBrowserLocale() || 'en'

        this.corona = new Corona()
    }

    // Dummy Init
    async init() {
        await this.corona.init()

        await Brazil.init(this)

        this.brazil = Brazil

        this.clearSelectedCity()

        if (this.isWeb) {
            this.openSideBar()
        }

        window.navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords

                const closestCities = this.brazil.getClosestCities([longitude, latitude])

                const interval = setInterval(() => {
                    if (this.flyToCity) {
                        this.setSelectedCity(closestCities[0].city, true)

                        clearInterval(interval)
                    }
                }, 300)
            },
            err => {
                console.log(err)
            },
            {
                timeout: 10000,
                maximumAge: 60000,
                enableHighAccuracy: true,
            }
        )

        this.status = 'ready'
    }
}

decorate(AppState, {
    isWeb: observable,
    initalHeight: observable,
    initialWidth: observable,
    showLiteCard: observable,
    blurSearchbar: observable,

    status: observable,
    locale: observable,

    longitude: observable,
    latitude: observable,

    searchBar: observable,
    setSearchBar: action,

    selectedCity: observable,
    setSelectedCity: action,

    sideBarIsOpen: observable,
    drawerContent: observable,
    closeSideBar: action,
    openSideBar: action,
})

export default createContext(new AppState())
