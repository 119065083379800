import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, TextField, IconButton } from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
    container: {
        height: 8,
        zIndex: 10000,
        width: '100%',
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
    },

    paper: {
        height: 50,
        width: '95%',
        display: 'flex',
    },

    input: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        marginRight: theme.spacing(1),
    },

    underline: {
        '&&&:before': {
            borderBottom: 'none',
        },
        '&&:after': {
            borderBottom: 'none',
        },
    },

    iconButton: {
        padding: 10,
    },

    autocomplete: {
        zIndex: 10000,
        width: '100%',
        display: 'flex',
    },
}))

export default React.memo(({ appState, sideBarIsOpen, mobile, width, onFocus = () => {}, blur = false }) => {
    const classes = useStyles()

    const openSideBar = () => {
        appState.openSideBar()
    }

    if (!appState.brazil.cities.length) return null

    return (
        <div className={classes.container} style={{ marginLeft: 0, maxWidth: mobile ? width : 400 }}>
            <Paper className={classes.paper}>
                <IconButton
                    onClick={() => !appState.sideBarIsOpen && openSideBar()}
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                >
                    {sideBarIsOpen ? <SearchIcon /> : <MenuIcon />}
                </IconButton>

                <Autocomplete
                    id="search-city"
                    options={appState.brazil.cities}
                    value={appState.searchBar}
                    getOptionLabel={option => {
                        if (option.name) {
                            return `${option.name} / ${option.ufState.short}`
                        }

                        return ''
                    }}
                    onChange={(event, city) => {
                        if (city) {
                            appState.setSelectedCity(city, true)
                        }
                    }}
                    onInputChange={event => {
                        if (event?.target?.value) {
                            appState.setSearchBar(event.target.value)
                        }
                    }}
                    className={classes.autocomplete}
                    renderInput={params => {
                        if (blur && params.InputProps.ref) {
                            // params.InputProps.ref.blur()
                        }

                        return (
                            <TextField
                                {...params}
                                onFocus={onFocus}
                                className={classes.input}
                                placeholder="Buscar Cidade"
                                InputProps={{
                                    ...params.InputProps,

                                    ref(node) {
                                        if (node) {
                                            if (blur) {
                                                node.children[0].blur()
                                            }
                                        }

                                        return params.InputProps.ref(node)
                                    },
                                    classes: {
                                        ...params.InputProps.classes,
                                        underline: classes.underline,
                                    },
                                }}
                            />
                        )
                    }}
                />
            </Paper>
        </div>
    )
})
