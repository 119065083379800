/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

export default observer(({ appState }) => {
    const location = useLocation()
    const [previousPathname, setPreviousPathname] = useState('')

    const { pathname } = location

    // custom effect hook to figure when path actually changes and send event
    useEffect(() => {
        if (!previousPathname) {
            setPreviousPathname(pathname)
        } else if (pathname !== previousPathname) {
            if (!appState.currentUser) {
                setPreviousPathname(pathname)
            } else {
                appState.tracker.trackNavigationEvent(previousPathname, pathname, currentPathname => {
                    setPreviousPathname(currentPathname)
                })
            }
        }
    }, [pathname])

    return null
})
