import * as mobx from 'mobx'

import getUfState from './getUFState'

import Cities from './Cities'

import Country from '../../../Models/Country'

import { getCsvAsJson } from '../../../utils/data'
import getMinute from '../../../utils/date/getMinute'

const { decorate, computed } = mobx

// Brazil
const cityDataUrl = 'https://raw.githubusercontent.com/wcota/covid19br/master/cases-brazil-cities.csv'

class Brazil extends Country {
    constructor() {
        super('brazil')

        this.name = 'brazil'
        this.displayName = 'Brasil'
    }

    async loadProjectionAndData() {
        this.projection = this.appState.corona.getCountryProjection(this.name)

        this.citiesStatistics = (await getCsvAsJson(cityDataUrl)).map(cityStatistic => {
            return {
                ...cityStatistic,
                deaths: Number(cityStatistic.deaths),
                totalCases: Number(cityStatistic.totalCases),
            }
        })
    }

    get totalCasesInCountry() {
        let totalCases = 0

        if (this.citiesStatistics) {
            this.citiesStatistics.forEach(citiesStatistic => {
                totalCases += citiesStatistic.totalCases
            })
        }

        return totalCases
    }

    async init(appState) {
        this.appState = appState

        await this.loadProjectionAndData()

        Cities.forEach(cityData => {
            cityData.ufState = getUfState(cityData.codigo_uf)

            const data = this.citiesStatistics.find(citiesStatistic => {
                const [cityName, stateShort] = citiesStatistic.city.split('/')

                return cityName === cityData.name && stateShort === cityData.ufState.short
            })

            if (!data) return

            const { totalCases, deaths } = data

            const getCityData = cityInstance => {
                const timeIndex = this.projection.daysOfPademic.findIndex(daysOfPademic =>
                    daysOfPademic.isSame(cityInstance.time, 'day')
                )

                const corrector =
                    (this.projection.projection[timeIndex] || 0) / (this.totalCasesInCountry || 1)

                const multiplier = (Number(totalCases) * corrector) / this.projection.projection[timeIndex]

                const applyCityMultiplier = el => Math.floor(el * multiplier)

                const cityTotalProjection = this.projection.projection.map(applyCityMultiplier)
                const cityDeadProjection = this.projection.deathProjections.map(applyCityMultiplier)
                const cityRecoveryProjection = this.projection.recoveryProjections.map(applyCityMultiplier)

                const latestProjectionCount = cityTotalProjection[cityTotalProjection.length - 1]

                const latestProjectionDay = cityInstance.time
                    .clone()
                    .startOf('days')
                    .add(cityTotalProjection.length - 1 - timeIndex, 'days')

                const factor =
                    (cityTotalProjection[timeIndex + 1] / cityTotalProjection[timeIndex]) ** (1 / 1440)

                const minutes = getMinute(cityInstance.time)

                const casesNow = cityTotalProjection[timeIndex] * factor ** minutes
                const deadNow = cityDeadProjection[timeIndex] * factor ** minutes
                const curedNow = cityRecoveryProjection[timeIndex] * factor ** minutes

                const casesPerMinute = Math.log(factor) * casesNow

                const minutesPerCase = 1 / casesPerMinute

                const noNewCasesToday = minutesPerCase.toString() === 'Infinity'

                const secondsPerCase = minutesPerCase * 60

                return {
                    noNewCasesToday,
                    latestProjectionDay,
                    latestProjectionCount,

                    official_dead: Math.floor(deaths),
                    official_infected: Math.floor(totalCases),

                    dead: Math.floor(deadNow),
                    cured: Math.floor(curedNow),
                    infected: Math.floor(casesNow),
                    casesPerMinute: Math.floor(casesPerMinute),
                    minutesPerCase: Math.floor(minutesPerCase),
                    secondsPerCase: Math.floor(secondsPerCase),
                }
            }

            this.addCity(cityData, getCityData)
        })

        super.init()
    }

    get plotData() {
        return this.cities.map((city, index) => {
            return {
                code: index,
                name: city.name,
                geohash: city.geohash,
                coordinates: city.coordinatesArray,
            }
        })
    }
}

decorate(Brazil, {
    plotData: computed,
})

export default new Brazil()
