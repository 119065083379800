import * as mobx from 'mobx'

import { getDistance } from 'geolib'

import City from './City'

const { decorate, computed } = mobx

class Country {
    constructor(name) {
        this.name = name
        this.cities = []
    }

    async init() {
        this.cities.forEach(city => {
            city.init()
        })
    }

    addCity(cityData, getCityData) {
        const city = new City(cityData, this, getCityData)

        this.cities.push(city)
    }

    getClosestCities(lngLat) {
        return this.cities
            .map(city => {
                return { city, distance: getDistance(lngLat, city.coordinates) }
            })
            .sort((a, b) => a.distance - b.distance)
    }

    get countryDetails() {
        const details = this.cities.reduce(
            (countryStats, city) => {
                if (
                    isNaN(city.sick) ||
                    isNaN(city.dead) ||
                    isNaN(city.cured) ||
                    isNaN(city.infected) ||
                    isNaN(city.latestProjectionCount)
                )
                    return countryStats

                countryStats.sick += city.sick
                countryStats.dead += city.dead
                countryStats.cured += city.cured
                countryStats.infected += city.infected
                countryStats.casesPerMinute += city.casesPerMinute
                countryStats.latestProjectionCount += city.latestProjectionCount

                if (!countryStats.latestProjectionDay) {
                    countryStats.latestProjectionDay = city.latestProjectionDay
                }

                return countryStats
            },
            {
                name: this.displayName,
                dead: 0,
                sick: 0,
                cured: 0,
                infected: 0,
                casesPerMinute: 0,
                latestProjectionCount: 0,
                latestProjectionDay: null,
            }
        )

        return details
    }
}

decorate(Country, {
    countryDetails: computed,
})

export default Country
