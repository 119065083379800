import React from 'react'

import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import CityDetails from '../Modules/CityDetails/CityDetails'

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
}))

export default observer(({ city }) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <CityDetails city={city} tooltip />
        </div>
    )
})
