import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { ReactComponent as SketchSVG } from '../assets/sketch.svg'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    text: {
        fontWeight: '300',
    },
    textWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3, 0),
    },
    icon: {
        maxWidth: 200,
    },
    iconWrapper: {
        textAlign: 'center',
    },
}))

export default React.memo(() => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.textWrapper}>
                <Typography variant="h3" gutterBottom className={classes.text}>
                    Em construção!
                </Typography>
            </div>

            <div className={classes.iconWrapper}>
                <SketchSVG className={classes.icon} />
            </div>
        </div>
    )
})
