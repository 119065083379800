import React, { useContext } from 'react'

import { Helmet } from 'react-helmet'

import { observer } from 'mobx-react-lite'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import AppState from './AppState'
import RouterListener from './RouterListener'

import Home from './screens/Home/Home'
import Loader from './Components/Loader'
import AdBanner from './Components/AdBanner'

const useStyles = makeStyles(() => ({
    app: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
}))

export default observer(() => {
    const classes = useStyles()

    const appState = useContext(AppState)

    let appContent

    if (appState.status === 'init') {
        appState.init()

        appContent = <Loader />
    } else {
        appContent = (
            <>
                <AdBanner appState={appState} />
                <BrowserRouter>
                    <RouterListener appState={appState} />

                    <Switch>
                        <Route path="/" component={Home} />
                    </Switch>
                </BrowserRouter>
            </>
        )
    }

    return (
        <div className={classes.app}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Siga o Corona</title>
                <link rel="canonical" href="https://www.iconfinder.com/icons/4443503/download/png/512" />
            </Helmet>
            {appContent}
        </div>
    )
})
