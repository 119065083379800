import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

import QRCode from 'react-qr-code'

import BTC from '../assets/btc.png'
import PicPay from '../assets/picpay.png'

const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: theme.spacing(1, 0),
    },
    mainListItem: {
        padding: theme.spacing(1),
    },
    icon: {
        maxWidth: 50,
        marginRight: theme.spacing(1),
    },
    action: {
        width: 100,
        backgroundColor: 'red',
    },
    title: {
        fontWeight: 300,
    },
}))

const Alert = React.memo(props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
})

const QRCodeDialog = React.memo(({ open, onClose, method, contetStyle, ...props }) => {
    return (
        <Dialog {...props} onClose={onClose} open={open}>
            <DialogTitle>
                <div>
                    <Typography variant="subtitle1">{method.title}</Typography>

                    <Typography variant="caption" gutterBottom>
                        {method.value}
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent style={contetStyle}>
                {method.qr ? <QRCode value={method.value} /> : null}
            </DialogContent>
        </Dialog>
    )
})

export default observer(() => {
    const classes = useStyles()

    const [modalMethod, setModalMethod] = useState(false)
    const [alertIsOpen, setAlertIsOpen] = useState(false)

    const copyAction = value => {
        const el = document.createElement('textarea')

        el.value = value
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        setAlertIsOpen(true)
    }

    const methods = [
        {
            title: 'Bitcoin',
            qr: true,
            copyAction,
            icon: <img style={{ width: 50 }} src={BTC} alt="Logo" />,
            value: 'bc1qdt92yg7hmyjmvegla0hczklxe448n0f08x843c',
        },
        {
            title: 'PicPay',
            qr: true,
            copyAction,
            icon: <img style={{ width: 50 }} src={PicPay} alt="Logo" />,
            value: 'https://picpay.me/yan.nunes',
        },
    ]

    return (
        <div>
            <Typography className={classes.title} variant="h4" gutterBottom>
                Qualquer ajuda é bem vinda!
            </Typography>
            &nbsp;
            <Typography variant="body2" gutterBottom>
                Fiz este site em meu tempo livre, para ajudar as pessoas a se informarem da real situação no
                Brasil. Sob o governo atual, o Ministério da Saúde não tem condições de monitorar nem de
                diagnosticar todos casos que ocorram.
            </Typography>
            &nbsp;
            <Typography variant="body2" gutterBottom>
                Qualquer quantia que seja doada vai para ajudar a manter esse site no ar. Quanto mais tempo no
                ar e mais pessoas se informarem, mais vidas podem ser salvas!
            </Typography>
            &nbsp;
            <List>
                {methods.map((method, key) => {
                    return (
                        <>
                            <ListItem disableGutters key={key}>
                                <ListItem
                                    button
                                    disableGutters
                                    className={classes.mainListItem}
                                    onClick={() => setModalMethod(method)}
                                >
                                    <ListItemIcon className={classes.icon}>{method.icon}</ListItemIcon>

                                    <div>
                                        <Typography variant="subtitle1">{method.title}</Typography>

                                        <Typography variant="caption" gutterBottom>
                                            {method.value}
                                        </Typography>
                                    </div>
                                </ListItem>

                                {method.copyAction ? (
                                    <IconButton onClick={() => method.copyAction(method.value)}>
                                        <FileCopyRoundedIcon />
                                    </IconButton>
                                ) : null}
                            </ListItem>
                        </>
                    )
                })}
            </List>
            <Snackbar open={alertIsOpen} autoHideDuration={6000} onClose={() => setAlertIsOpen(false)}>
                <Alert onClose={() => setAlertIsOpen(false)} severity="success">
                    Salvo na área de transferência! Pronto para colar onde quiser.
                </Alert>
            </Snackbar>
            <QRCodeDialog
                contetStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: 32,
                }}
                open={!!modalMethod}
                method={modalMethod}
                style={{ zIndex: 10000 }}
                onClose={() => setModalMethod(false)}
            />
        </div>
    )
})
