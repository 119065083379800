const map = [
    { code: 11, name: 'Rondônia', short: 'RO' },
    { code: 12, name: 'Acre', short: 'AC' },
    { code: 13, name: 'Amazonas', short: 'AM' },
    { code: 14, name: 'Roraima', short: 'RR' },
    { code: 15, name: 'Pará', short: 'PA' },
    { code: 16, name: 'Amapá', short: 'AP' },
    { code: 17, name: 'Tocantins', short: 'TO' },
    { code: 21, name: 'Maranhão', short: 'MA' },
    { code: 22, name: 'Piauí', short: 'PI' },
    { code: 23, name: 'Ceará', short: 'CE' },
    { code: 24, name: 'Rio Grande do Norte', short: 'RN' },
    { code: 25, name: 'Paraíba', short: 'PB' },
    { code: 26, name: 'Pernambuco', short: 'PE' },
    { code: 27, name: 'Alagoas', short: 'AL' },
    { code: 28, name: 'Sergipe', short: 'SE' },
    { code: 29, name: 'Bahia', short: 'BA' },
    { code: 31, name: 'Minas Gerais', short: 'MG' },
    { code: 32, name: 'Espírito Santo', short: 'ES' },
    { code: 33, name: 'Rio de Janeiro', short: 'RJ' },
    { code: 35, name: 'São Paulo', short: 'SP' },
    { code: 41, name: 'Paraná', short: 'PR' },
    { code: 42, name: 'Santa Catarina', short: 'SC' },
    { code: 43, name: 'Rio Grande do Sul', short: 'RS' },
    { code: 50, name: 'Mato Grosso do Sul', short: 'MS' },
    { code: 51, name: 'Mato Grosso', short: 'MT' },
    { code: 52, name: 'Goiás', short: 'GO' },
    { code: 53, name: 'Distrito Federal', short: 'DF' },
]

export default (short, code, name) => {
    return map.find(state =>
        [short, code, name].some(data => state.code === data || state.name === data || state.short === data)
    )
}
