// eslint-disable-next-line
let venderPrefix = ''

// Get CSS vendor prefix
try {
    const styleObj = document.createElement('div').style
    const prefix = /^(webkit|moz|ms|o)(?=[A-Z])/
    for (const key in styleObj) {
        if (prefix.test(key)) {
            venderPrefix = `-${key.match(prefix)[0]}-`
            break
        }
    }
} catch (error) {
    // document not available
}

const PREFIX = venderPrefix === '-webkit-' ? venderPrefix : ''

const CURSOR = {
    GRABBING: `${PREFIX}grabbing`,
    GRAB: `${PREFIX}grab`,
    POINTER: 'pointer',
}

const getCursor = ({ isDragging, isHovering }) => {
    if (isHovering) {
        return CURSOR.POINTER
    }

    if (isDragging) {
        return CURSOR.GRABBING
    }

    return CURSOR.GRAB
}

export default getCursor
