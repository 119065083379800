const axios = require('axios')
const csv = require('csvtojson')

export default url => {
    return new Promise(async resolve => {
        const { data: csvString } = await axios.get(url, { responseType: 'text' })

        const data = []

        csv()
            .fromString(csvString)
            .subscribe(json => {
                data.push(json)
            })
            .on('done', () => {
                resolve(data)
            })
    })
}
