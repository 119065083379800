import React from 'react'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined'

import humanReadable from '../../utils/number/humanReadable'
import minutesToHours from '../../utils/date/minutesToHours'

const useStyles = makeStyles(theme => ({
    list: props => {
        if (props.tooltip) {
            return {
                left: -theme.spacing(1),
            }
        }
        return {}
    },
    icon: {},
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    countIndicator: {
        display: 'flex',
        alignItems: 'center',
    },
    countDeltaIndicator: {
        paddingLeft: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
    },

    detailedOverview: {
        fontSize: 20,
        display: 'flex',
        fontWeight: 300,
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },

    detailedOverviewBold: {
        fontSize: 20,
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        padding: theme.spacing(0, 0.5),
    },
}))

const CountIndication = observer(({ city, type, deltaClassName, ...props }) => {
    let text = ''
    let color = ''

    switch (type) {
        case 'infected':
            text = 'Infectados'
            color = '#f44336'
            break
        case 'cured':
            text = 'Recuperados'
            color = '#4caf50'
            break
        case 'dead':
            text = 'Óbitos'
            color = '#000'
            break
        case 'sick':
            text = 'Doentes'
            color = '#ff9800'
            break

        default:
            break
    }

    const count = city[type] ? humanReadable(city[type]) : city[type]

    const delta = city[`${type}SinceStart`]
        ? humanReadable(city[`${type}SinceStart`])
        : city[`${type}SinceStart`]

    const official = city[`official_${type}`]
        ? humanReadable(city[`official_${type}`])
        : city[`official_${type}`]

    const DeltaText = () => (
        <Typography style={{ color }} className={deltaClassName}>{` (+${delta})`}</Typography>
    )

    console.log(official)

    const OfficialText = () => <Typography className={deltaClassName}>{` (${official} oficiais)`}</Typography>

    return (
        <div {...props}>
            {count} {text}
            {delta ? <DeltaText {...props} /> : null}
            {official ? <OfficialText {...props} /> : null}
        </div>
    )
})

export default observer(({ city, tooltip, lite }) => {
    const classes = useStyles({ tooltip })

    const OverView = () => (
        <List className={classes.list} component="nav">
            <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.icon}>
                    <AccessibilityNewOutlinedIcon style={{ color: '' }} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <CountIndication
                            className={classes.countIndicator}
                            deltaClassName={classes.countDeltaIndicator}
                            type="infected"
                            city={city}
                        />
                    }
                />
            </ListItem>
            <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.icon}>
                    <LocalHospitalIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <CountIndication
                            className={classes.countIndicator}
                            deltaClassName={classes.countDeltaIndicator}
                            type="sick"
                            city={city}
                        />
                    }
                />
            </ListItem>
            <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.icon}>
                    <CheckCircleOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <CountIndication
                            className={classes.countIndicator}
                            deltaClassName={classes.countDeltaIndicator}
                            type="cured"
                            city={city}
                        />
                    }
                />
            </ListItem>
            <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.icon}>
                    <ErrorOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <CountIndication
                            className={classes.countIndicator}
                            deltaClassName={classes.countDeltaIndicator}
                            type="dead"
                            city={city}
                        />
                    }
                />
            </ListItem>
        </List>
    )

    const Bold = text => <Typography className={classes.detailedOverviewBold}>{text}</Typography>

    const CasesPerTimeOverview = () => {
        const content = []

        if (city.noNewCasesToday) {
            content.push('Nenhum novo caso hoje')
        } else if (city.casesPerMinute > 0) {
            content.push(
                'Estão ocorrendo',
                Bold(humanReadable(city.casesPerMinute)),
                'novo(s) caso a cada minuto'
            )
        } else if (city.minutesPerCase > 0) {
            if (city.minutesPerCase >= 59) {
                const hours = minutesToHours(city.minutesPerCase)

                content.push(
                    'Estão ocorrendo',
                    Bold(humanReadable(1)),
                    'novo caso a cada',
                    Bold(hours),
                    'horas'
                )
            } else {
                content.push(
                    'Estão ocorrendo',
                    Bold(humanReadable(1)),
                    'novo caso a cada',
                    Bold(city.minutesPerCase),
                    'minutos'
                )
            }
        } else if (city.secondsPerCase > 0) {
            content.push(
                'Estão ocorrendo',
                Bold(humanReadable(1)),
                'novo caso a cada',
                Bold(city.secondsPerCase),
                'segundos'
            )
        }

        if (!content.length) return null

        return (
            <div className={classes.detailedOverview}>
                {content[0] || null}
                {content[1] || null}
                {content[2] || null}
                {content[3] || null}
                {content[4] || null}
                {content[5] || null}
            </div>
        )
    }

    const TotalCasesProjection = () => {
        const content = [
            'Estima-se que',
            Bold(humanReadable(city.latestProjectionCount)),
            'pessoas serão infectadas até dia',
            Bold(city.latestProjectionDay),
        ]

        return (
            <div className={classes.detailedOverview}>
                {content[0] || null}
                {content[1] || null}
                {content[2] || null}
                {content[3] || null}
            </div>
        )
    }

    return (
        <>
            <OverView />

            {!lite ? (
                <>
                    <Divider />
                    <CasesPerTimeOverview />
                    <TotalCasesProjection />
                </>
            ) : null}
        </>
    )
})
