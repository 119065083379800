import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import useWindowResize from '../hooks/useWindowResize'

const drawerWidth = 445

const adSizesByWidth = width => {
    if (width > 1416) {
        return {
            width: 970,
            height: 90,
        }
    }
    if (width > 1174) {
        return {
            width: 728,
            height: 90,
        }
    }

    if (width > 914) {
        return {
            width: 468,
            height: 60,
        }
    }

    if (width > 768) {
        return {
            width: 320,
            height: 50,
        }
    }

    return {
        width: 320,
        height: 0,
    }
}

const useStyles = makeStyles(theme => ({
    wrapper: props => {
        const { height: adHeight } = adSizesByWidth(props.width)

        return {
            top: 0,
            right: 0,
            zIndex: 100,
            display: 'flex',
            position: 'absolute',

            width: props.width - drawerWidth,
            height: theme.spacing(2) + adHeight,
        }
    },
    adContainer: props => {
        const baseStyles = {
            top: 0,
            right: 0,
            zIndex: 101,
            minWidth: 50,
            minHeight: 50,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            width: 250,
            height: 50,
        }

        if (!props.mobile) {
            const adSize = adSizesByWidth(props.width)

            const containerWidth = props.width - drawerWidth

            const left = (containerWidth - adSize.width) / 2

            const finalStyle = {
                ...baseStyles,
                ...adSize,

                left,
                top: theme.spacing(1),
            }

            return finalStyle
        }

        return baseStyles
    },
}))

export default React.memo(() => {
    return null

    const [width] = useWindowResize()

    const classes = useStyles({ mobile: false, width })

    useEffect(() => {
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [])

    return (
        <div className={classes.wrapper}>
            <div className={classes.adContainer}>
                <ins
                    data-ad-client="ca-pub-4244452724990746"
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-format="horizontal"
                />
            </div>
        </div>
    )
})
