import React from 'react'

import Chip from '@material-ui/core/Chip'
import grey from '@material-ui/core/colors/grey'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    wrapper: props => {
        const baseStyles = {
            right: 0,
            zIndex: 10,
            position: 'absolute',
            padding: theme.spacing(2),
        }

        if (props.mobile) {
            return {
                ...baseStyles,
                top: theme.spacing(8),
                width: `calc(100% - ${theme.spacing(2) * 2}px)`,
            }
        }

        return {
            ...baseStyles,
            top: props.showAds ? 90 : !props.sideBarIsOpen ? theme.spacing(8) : 0,
            width: `calc(100% - ${props.sideBarIsOpen ? '445' : '0'}px - ${theme.spacing(2) * 2}px)`,
        }
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: theme.spacing(18),
    },
    chip: {
        marginBottom: theme.spacing(2),
        backgroundColor: grey['50'],
    },
}))

export default observer(({ appState }) => {
    const classes = useStyles({
        showAds: false,
        mobile: !appState.isWeb,
        sideBarIsOpen: appState.sideBarIsOpen,
    })

    const handleClick = drawerContent => {
        appState.setDrawerContent(drawerContent)
    }

    const actions = [
        // {
        //     icon: <HelpOutlineOutlinedIcon />,
        //     label: 'Muitos casos?',
        //     onClick: () => handleClick('faq'),
        // },
        {
            icon: <FavoriteBorderRoundedIcon />,
            label: 'Quero Ajudar!',
            onClick: () => handleClick('donation'),
        },
    ]

    return (
        <div className={classes.wrapper}>
            <div className={classes.buttonsWrapper}>
                {actions.map((action, key) => {
                    return (
                        <Chip
                            key={key}
                            clickable
                            size="medium"
                            icon={action.icon}
                            label={action.label}
                            onClick={action.onClick}
                            className={classes.chip}
                        />
                    )
                })}
            </div>
        </div>
    )
})
