import * as mobx from 'mobx'

import md5 from 'md5'

import moment from 'moment'

const { decorate, observable, action, computed } = mobx

class City {
    infected = 0

    dead = 0

    cured = 0

    start_infected = 0

    start_dead = 0

    start_cured = 0

    casesPerMinute = 0

    time = moment()

    initialized = false

    casesPerMinute = false

    minutesPerCase = 0

    secondsPerCase = 0

    noNewCasesToday = 0

    latestProjectionCount = 0

    latestProjectionDay = null

    official_infected = 0

    official_dead = 0

    constructor({ name, ufState, latitude, longitude }, country, getCityData) {
        this.name = name
        this.latitude = latitude
        this.longitude = longitude
        this.ufState = ufState

        this.state = 'Sob controle'

        this.country = country
        this.getCityData = getCityData
    }

    get geohash() {
        return md5(`${this.name}${this.latitude}${this.longitude}`)
    }

    getCityData() {
        throw new Error('not implemented')
    }

    async updateCityData() {
        const {
            dead,
            cured,
            infected,
            casesPerMinute,
            minutesPerCase,
            secondsPerCase,
            noNewCasesToday,
            latestProjectionCount,
            official_dead,
            official_infected,
            latestProjectionDay,
        } = await this.getCityData(this)

        this.dead = dead
        this.cured = cured
        this.infected = infected

        this.official_dead = official_dead
        this.official_infected = official_infected

        this.casesPerMinute = casesPerMinute
        this.minutesPerCase = minutesPerCase
        this.secondsPerCase = secondsPerCase
        this.noNewCasesToday = noNewCasesToday

        this.latestProjectionCount = latestProjectionCount

        if (latestProjectionDay) this.latestProjectionDay = latestProjectionDay.format('DD/MM/YYYY')

        // this.dead = dead
        // this.infected = infected

        if (!this.initialized) {
            this.start_infected = this.infected
            this.start_dead = this.dead
            this.start_cured = this.cured

            this.initialized = true
        }
    }

    async init() {
        this.interval = setInterval(() => {
            this.updateTime()

            this.updateCityData()
        }, 11000)

        this.updateCityData()
    }

    updateTime(time = moment()) {
        this.time = time
    }

    get coordinates() {
        return {
            latitude: this.latitude,
            longitude: this.longitude,
        }
    }

    get coordinatesArray() {
        return [this.longitude, this.latitude]
    }

    get infectedSinceStart() {
        return this.infected - this.start_infected
    }

    get deadSinceStart() {
        return this.dead - this.start_dead
    }

    get curedSinceStart() {
        return this.cured - this.start_cured
    }

    get sick() {
        return this.infected - this.dead - this.cured
    }

    get start_sick() {
        return this.start_infected - this.start_dead - this.start_cured
    }

    get sickSinceStart() {
        return this.sick - this.start_sick
    }
}

decorate(City, {
    time: observable,
    infected: observable,
    dead: observable,
    cured: observable,

    official_infected: observable,
    official_dead: observable,

    casesPerMinute: observable,
    minutesPerCase: observable,
    secondsPerCase: observable,
    noNewCasesToday: observable,

    sick: computed,

    start_infected: observable,
    start_dead: observable,
    start_cured: observable,
    start_sick: computed,

    infectedSinceStart: computed,
    deadSinceStart: computed,
    curedSinceStart: computed,
    sickSinceStart: computed,

    latestProjectionCount: observable,
    latestProjectionDay: observable,

    init: action,
    updateTime: action,
})

export default City
