import React from 'react'

import { observer } from 'mobx-react-lite'

import { Drawer, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import CityDetails from './CityDetails/CityDetails'

import Donation from './Donation'

const drawerWidth = 445

const useStyles = makeStyles(theme => ({
    drawerContent: {
        display: 'flex',
        width: drawerWidth,
        flexDirection: 'column',
    },

    drawerHeader: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        width: drawerWidth,
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(1.5),
        marginRight: theme.spacing(1),
    },

    sidebarContentContainer: {
        flexGrow: 1,
        marginTop: 10,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5),
    },
}))

export default observer(({ appState }) => {
    const classes = useStyles()

    let drawerContent = null

    let icon = null

    let clickHandler = () => {}

    switch (appState.drawerContent) {
        case 'city':
            drawerContent = appState.selectedCity ? (
                <CityDetails classes={classes} city={appState.selectedCity} />
            ) : null

            icon = <ChevronLeftIcon />

            clickHandler = () => appState.closeSideBar()
            break
        case 'faq':
            drawerContent = <div>FAQ</div>

            icon = <CloseRoundedIcon />

            clickHandler = () => {
                appState.setDrawerContent('city')
            }
            break
        case 'donation':
            drawerContent = <Donation appState={appState} />

            icon = <CloseRoundedIcon />

            clickHandler = () => {
                appState.setDrawerContent('city')
            }
            break

        default:
            break
    }

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            className={classes.drawer}
            open={appState.sideBarIsOpen}
            onClose={() => appState.closeSideBar()}
        >
            <div className={classes.drawerContent}>
                <div className={classes.drawerHeader}>
                    <IconButton style={{ marginRight: 4 }} onClick={() => clickHandler()}>
                        {icon}
                    </IconButton>
                </div>

                <div className={classes.sidebarContentContainer}>{drawerContent}</div>
            </div>
        </Drawer>
    )
})
