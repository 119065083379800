import { FlyToInterpolator } from '@deck.gl/core'

const easings = [
    0.9207356533556915,
    0.9268145031331236,
    0.9326218413197387,
    0.9381616793732098,
    0.9434380991115585,
    0.9484552348377863,
    0.9532172572820469,
    0.9577283592268117,
    0.9619927426848138,
    0.9660146075048951,
    0.9697981412869,
    0.9733475104932494,
    0.9766668526515562,
    0.9797602695494695,
    0.982631821329728,
    0.9852855214000505,
    0.9877253320789553,
    0.9899551609047772,
    0.9919788575410575,
    0.9938002112170482,
    0.9954229486473193,
    0.9968507323793536,
    0.9980871595225894,
    0.9991357608165962,
    1,
    0.9991357608165962,
    0.9980871595225894,
    0.9968507323793536,
    0.9954229486473193,
    0.9938002112170482,
    0.9919788575410575,
    0.9899551609047772,
    0.9877253320789553,
    0.9852855214000505,
    0.982631821329728,
    0.9797602695494695,
    0.9766668526515562,
    0.9733475104932494,
    0.9697981412869,
    0.9660146075048951,
    0.9619927426848138,
    0.9577283592268117,
    0.9532172572820469,
    0.9484552348377863,
    0.9434380991115585,
    0.9381616793732098,
    0.9326218413197387,
    0.9268145031331236,
]

export default source => ({
    alphaStep: 0,

    baseAlpha: 153,

    alphaCoeficient: 1,

    get color() {
        return [244, 67, 54, Math.round(this.baseAlpha * this.alphaCoeficient)]
    },

    incAlpha() {
        this.alphaStep += 1

        if (easings.length - 1 < this.alphaStep) {
            this.alphaStep = 0
        }

        this.alphaCoeficient = easings[this.alphaStep] ** 5
    },

    viewState: {
        zoom: 4,
        opacity: 0.4,
        latitude: -12.9777,
        longitude: -38.5016,
        transitionInterpolator: new FlyToInterpolator(),
    },

    setMapState(state) {
        this.viewState = {
            ...this.viewState,
            ...state,
        }
    },

    selectedCity: null,

    flyToCity(city) {
        this.setMapState({
            ...city.coordinates,
            zoom: 10,
            transitionDuration: 1400,
        })
    },

    onCitySelect(city, flyTo = true) {
        source.appState.setSelectedCity(city, flyTo)
    },
})
