import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import Map from '../../Modules/Map/Map'
import SideBar from '../../Modules/SideBar'
import Toolbar from '../../Modules/Toolbar'
import BottomBar from '../../Modules/BottomBar'
import SearchBar from '../../Components/SearchBar'

import AppState from '../../AppState'

import useWindowResize from '../../hooks/useWindowResize'

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },

    mapContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
}))

export default observer(() => {
    const classes = useStyles()
    const appState = useContext(AppState)

    const [width, height] = useWindowResize()

    if ((height && !appState.initalHeight) || appState.isWeb) {
        appState.initalHeight = height
    }

    if (width && !appState.initialWidth) {
        appState.initialWidth = width
    }

    // First render
    if (width > 0 && typeof appState.isWeb === 'undefined') {
        appState.isWeb = width > 768
    }

    const handleSearchBarFocus = focusIn => () => {
        appState.showLiteCard = !focusIn
    }

    return (
        <div className={classes.container}>
            <SearchBar
                mobile={!appState.isWeb}
                width={width}
                blur={appState.blurSearchbar}
                sideBarIsOpen={appState.sideBarIsOpen}
                appState={appState}
                onFocus={handleSearchBarFocus(true)}
                onBlur={handleSearchBarFocus(false)}
            />

            <Toolbar horizontal appState={appState} />

            <div className={classes.mapContainer}>
                {appState.isWeb ? <SideBar appState={appState} /> : null}

                <Map appState={appState} />

                {!appState.isWeb ? <BottomBar appState={appState} /> : null}
            </div>
        </div>
    )
})
