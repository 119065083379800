import React from 'react'

import DataUsageIcon from '@material-ui/icons/DataUsage'

import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined'
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined'

const Tabs = [
    {
        key: 'statistics',
        name: 'Estatísticas',
        icon: () => <BarChartOutlinedIcon />,
    },
    {
        key: 'news',
        name: 'Notícias',
        icon: () => <ChromeReaderModeOutlinedIcon />,
    },
    {
        key: 'projections',
        name: 'Previsões',
        icon: () => <DataUsageIcon />,
    },
]

const tabMap = Tabs.reduce((tabs, tab) => {
    tabs[tab.key] = tab.key

    return tabs
}, {})

export default Tabs

export { tabMap }
