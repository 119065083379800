import React, { useState, useContext } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'

import shortid from 'shortid'

import DeckGL from '@deck.gl/react'
import { StaticMap } from 'react-map-gl'
import { ScatterplotLayer } from '@deck.gl/layers'

import { Paper } from '@material-ui/core'

import useInterval from '../../hooks/useInterval'
import useWindowResize from '../../hooks/useWindowResize'

import MapState from './MapState'
import AppState from '../../AppState'

import getCursor from './getCursor'
import CityTooltip from '../../Components/CityTooltip'

const getValidNumber = number => {
    if (isNaN(number)) return 0

    return number
}

const HoveringCityTootip = React.memo(({ currentCity, country, zoom, isWeb }) => {
    if (!isWeb) return null
    if (!currentCity) return null

    const calculatedToolTipMargin = zoom * Math.log(zoom) ** Math.sqrt(zoom / 5 > 1 ? 2 : zoom / 5)

    const cityInstance = country.cities.find(city => {
        return currentCity.object.geohash === city.geohash
    })

    let margin = 15

    if (calculatedToolTipMargin > margin) {
        margin = calculatedToolTipMargin
    }

    const top = getValidNumber(currentCity.y - 90)
    const left = getValidNumber(currentCity.x + margin)

    return (
        <Paper
            style={{
                top,
                left,
                zIndex: 1,
                position: 'absolute',
                pointerEvents: 'none',
            }}
        >
            <CityTooltip city={cityInstance} />
        </Paper>
    )
})

const getClickDistance = zoom => {
    const data = [0, 0, 0, 0, 60000, 26000, 12000, 6000, 7000, 3250, 3000, 3000, 3000]

    if (zoom >= 7) return 3000
    if (zoom <= 4) return 60000

    const lowest = data[Math.floor(zoom)]
    const higest = data[Math.ceil(zoom)]

    const deltaY = higest - lowest

    const slope = deltaY / (Math.ceil(zoom) - Math.floor(zoom) || 1)

    if (slope === 0) return data[zoom]

    const constantValue = -slope * Math.ceil(zoom)

    const result = slope * zoom + constantValue

    return result
}

export default observer(() => {
    const [width] = useWindowResize()
    const appState = useContext(AppState)
    const mapState = useLocalStore(MapState, { appState })
    const [hoveringCity, setHoveringCity] = useState(null)

    useInterval(() => {
        mapState.incAlpha()
    }, 1000 / 30)

    appState.flyToCity = mapState.flyToCity

    const layer = new ScatterplotLayer({
        // Random id in every render so that the color gets updated
        id: shortid.generate(),
        // data: appState.brazil.plotData,
        data: appState.brazil.plotData.map(el => {
            el.color = mapState.color

            return el
        }),
        pickable: true,
        opacity: 1,
        stroked: true,
        filled: true,
        radiusScale: mapState.viewState.zoom * 5,
        radiusMinPixels: 4,
        radiusMaxPixels: width / 2,
        // lineWidthMinPixels: 5,
        getRadius: () => 50,
        getLineColor: point => point.color,
        getFillColor: point => point.color,
        getPosition: point => point.coordinates,
    })

    const onDeckGlViewStateChange = ({ viewState }) => {
        if (
            viewState.longitude !== mapState.viewState.longitude ||
            viewState.zoom !== mapState.viewState.zoom ||
            viewState.latitude !== mapState.viewState.latitude
        ) {
            mapState.setMapState(viewState)
        }
    }

    const onDeckGlMapHover = point => {
        if (!hoveringCity) {
            if (point.color) {
                setHoveringCity(point)
            }
        } else if (!point.color) {
            setHoveringCity(null)
        }
    }

    const onDeckGlClick = event => {
        const clickDistance = getClickDistance(mapState.viewState.zoom)

        const closestCities = appState.brazil.getClosestCities(event.lngLat)

        const closestCity = closestCities.filter(city => city.distance < clickDistance)[0]

        let cityToUse = hoveringCity && hoveringCity.object

        if (!cityToUse && closestCity) {
            cityToUse = closestCity.city
        }

        if (cityToUse) {
            if (closestCity) {
                if (closestCity.city.geohash !== cityToUse.geohash) {
                    cityToUse = closestCity.city
                }
            }

            const cityInstance = appState.brazil.cities.find(city => city.geohash === cityToUse.geohash)

            if (cityInstance) {
                mapState.onCitySelect(cityInstance)

                setHoveringCity({ object: cityInstance })
            }
        } else {
            appState.clearSelectedCity()
        }
    }

    return (
        <DeckGL
            initialViewState={{ ...mapState.viewState, bearing: 0, pitch: 0 }}
            width="100%"
            height={appState.initalHeight}
            layers={[layer]}
            onViewStateChange={onDeckGlViewStateChange}
            onClick={onDeckGlClick}
            onHover={onDeckGlMapHover}
            getCursor={state => getCursor({ ...state, isHovering: !!hoveringCity })}
            controller
        >
            <StaticMap
                attributionControl={false}
                transitionDuration={0}
                {...mapState.viewState}
                width="100%"
                height="100%"
                style={{ position: 'absolute', right: 0 }}
                mapboxApiAccessToken="pk.eyJ1IjoieWFubnVuZXMiLCJhIjoiY2s4MXRvOHp6MGNncjNmcndoZHg2MmNmcyJ9.34p8dglr-P4cmqNXo7PSeg"
            />

            <HoveringCityTootip
                isWeb={appState.isWeb}
                country={appState.brazil}
                currentCity={hoveringCity}
                zoom={mapState.viewState.zoom}
            />
        </DeckGL>
    )
})
