import React from 'react'

import { observer } from 'mobx-react-lite'

import { SwipeableDrawer, Paper, Grow } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import CityDetails from './CityDetails/CityDetails'

import Donation from './Donation'

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: 400,
        margin: theme.spacing(),
        padding: theme.spacing(2),
    },

    paperContainer: {
        bottom: 0,
        zIndex: 2,
        width: '100%',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
    },

    drawerContent: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
    },

    handle: {
        bottom: 0,
        width: '100%',
        position: 'absolute',
    },
}))

export default observer(({ appState }) => {
    const classes = useStyles()

    const toggleDrawer = (anchor, open) => () => {
        if (open) {
            appState.openSideBar()
        } else {
            appState.closeSideBar()
        }
    }

    const openCity = () => {
        appState.setSelectedCity(appState.selectedCity, false, true)
    }

    let drawerContent = null

    switch (appState.drawerContent) {
        case 'city':
            drawerContent = appState.selectedCity ? (
                <CityDetails
                    closeSideBar={() => {
                        appState.closeSideBar()
                    }}
                    classes={classes}
                    city={appState.selectedCity}
                />
            ) : null
            break
        case 'faq':
            drawerContent = <div>FAQ</div>

            break
        case 'donation':
            drawerContent = <Donation appState={appState} />
            break

        default:
            break
    }

    return (
        <>
            <SwipeableDrawer
                anchor="bottom"
                disableSwipeToOpen={false}
                open={appState.sideBarIsOpen}
                onOpen={toggleDrawer('bottom', true)}
                onClose={toggleDrawer('bottom', false)}
            >
                <div className={classes.drawerContent}>{drawerContent}</div>
            </SwipeableDrawer>

            <Grow in={appState.showLiteCard}>
                <div className={classes.paperContainer}>
                    <Paper
                        style={{ maxWidth: 400, width: '100%' }}
                        elevation={2}
                        onClick={openCity}
                        className={classes.paper}
                    >
                        {appState.selectedCity ? (
                            <CityDetails
                                lite
                                closeSideBar={() => {
                                    appState.closeSideBar()
                                }}
                                classes={classes}
                                city={appState.selectedCity}
                            />
                        ) : null}
                    </Paper>
                </div>
            </Grow>
        </>
    )
})
